var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view-producer" }, [
    _vm._m(0),
    _c(
      "div",
      {
        directives: [
          {
            name: "click-outside",
            rawName: "v-click-outside",
            value: _vm.closeMenuDropdown,
            expression: "closeMenuDropdown",
          },
        ],
        staticClass: "dropdown",
      },
      [
        _c(
          "a",
          {
            staticClass:
              "formInput formInput--fullWidth formInput--selectCustom",
            on: { click: _vm.toggleMenuDropdown },
          },
          [
            _vm._v(_vm._s(_vm.selectedPeriod.showText)),
            _c("icon", { attrs: { name: "arrow-down" } }),
          ],
          1
        ),
        _vm.menuDropDown
          ? _c("ul", { staticClass: "dropdown__menu" }, [
              _c(
                "li",
                {
                  staticClass: "dropdown__item dropdown__item--sm",
                  class: {
                    isActive: _vm.selectedPeriod.id === _vm.periods[0].id,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.setPeriod(0)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.periods[0].showText))]
              ),
              _c(
                "li",
                {
                  staticClass: "dropdown__item dropdown__item--sm",
                  class: {
                    isActive: _vm.selectedPeriod.id === _vm.periods[1].id,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.setPeriod(1)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.periods[1].showText))]
              ),
            ])
          : _vm._e(),
      ]
    ),
    _c("section", { staticClass: "section" }, [
      _c(
        "div",
        { staticClass: "container container--fullWidth wrapper--filled" },
        [
          _vm.isLoading || !_vm.detailedBeatPlays
            ? _c("VLoadSpinner")
            : [
                _c("h2", { staticClass: "h-mb-30" }, [_vm._v("Beats Plays")]),
                _c("BeatPlays", {
                  attrs: {
                    chartPoints: _vm.detailedBeatPlays,
                    showLast7Days: _vm.selectedPeriod.id === _vm.periods[0].id,
                  },
                }),
              ],
        ],
        2
      ),
    ]),
    _c("section", { staticClass: "section" }, [
      _c(
        "div",
        { staticClass: "container container--fullWidth wrapper--filled" },
        [
          _vm.isLoading || !_vm.detailedPageVisits
            ? _c("VLoadSpinner")
            : [
                _c("h2", { staticClass: "h-mb-30" }, [_vm._v("Page Views")]),
                _c("PageViews", {
                  attrs: {
                    chartPoints: _vm.detailedPageVisits,
                    showLast7Days: _vm.selectedPeriod.id === _vm.periods[0].id,
                  },
                }),
              ],
        ],
        2
      ),
    ]),
    _c("section", { staticClass: "section" }, [
      _c(
        "div",
        { staticClass: "container container--fullWidth wrapper--filled" },
        [
          _vm.isLoading || !_vm.detailedBeatUploads
            ? _c("VLoadSpinner")
            : [
                _c("h2", { staticClass: "h-mb-30" }, [_vm._v("Beats Uploads")]),
                _c("BeatUploads", {
                  attrs: {
                    chartPoints: _vm.detailedBeatUploads,
                    showLast7Days: _vm.selectedPeriod.id === _vm.periods[0].id,
                  },
                }),
              ],
        ],
        2
      ),
    ]),
    _c("section", { staticClass: "section" }, [
      _c(
        "div",
        { staticClass: "container container--fullWidth wrapper--filled" },
        [
          _vm.isLoading || !_vm.detailedSignups
            ? _c("VLoadSpinner")
            : [
                _c("h2", { staticClass: "h-mb-30" }, [_vm._v("Signups")]),
                _c("Signups", {
                  attrs: {
                    chartPoints: _vm.detailedSignups,
                    showLast7Days: _vm.selectedPeriod.id === _vm.periods[0].id,
                  },
                }),
              ],
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", { staticClass: "header" }, [
      _c("div", { staticClass: "container headerContainer" }, [
        _c("div", { staticClass: "headerContainer__heading" }, [
          _c("h3", [_vm._v("Dashboard")]),
          _c("h1", [_vm._v("Overview")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }